//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-404:_4060,_8428,_5680,_8812,_7384,_7484,_6604,_3764;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-404')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-404', "_4060,_8428,_5680,_8812,_7384,_7484,_6604,_3764");
        }
      }catch (ex) {
        console.error(ex);
      }